import React from 'react'
import {graphql, Link} from 'gatsby'
import styled from 'styled-components'
import HeaderPost from 'los-design-system/components/HeaderPost'
import ThemeProvider from 'los-design-system/components/ThemeProvider'
import Box from 'los-design-system/components/Box'
import Container from 'los-design-system/components/Container'
import Grid from 'los-design-system/components/Grid'
import FeaturedPost from 'los-design-system/components/FeaturedPost'
import Tabs from 'los-design-system/components/Tabs'
import ResetCss from 'los-design-system/components/reset'
import Footer from 'los-design-system/components/Footer'
import SectionTitleSubtitle from 'los-design-system/components/SectionTitleSubtitle'

// Components
import theme from '../theme'
import Navigation from '../components/Navigation'
import PostCategory from '../components/PostCategory'
import Subscribe from '../components/Subscribe'
import Seo from '../components/Seo'

const A = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

interface BlogProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
    latest: {
      edges: {
        node: {
          fields: {
            slug: string
          }
          frontmatter: {
            author: string
            avatar: string
            coverImage: string
            published: string
            tag: string
            title: string
          }
        }
      }[]
    }
    featured: {
      edges: {
        node: {
          fields: {
            slug: string
          }
          frontmatter: {
            author: string
            avatar: string
            coverImage: string
            published: string
            tag: string
            title: string
          }
        }
      }[]
    }
    tagsCount: {
      group: {
        fieldValue: 'news' | 'tips' | 'resources' | 'updates'
        totalCount: number
      }[]
    }
  }
}

function Blog({data}: BlogProps) {
  const {
    site: {
      siteMetadata: {title},
    },
    site: {
      siteMetadata: {description},
    },
    latest,
    featured,
    tagsCount,
  } = data

  const tags = tagsCount.group.reduce(
    (prevArr, obj) => {
      const tag = obj.fieldValue
      let tagTitle = ''

      if (tag === 'updates') {
        tagTitle = 'Product updates'
      } else if (tag === 'news') {
        tagTitle = 'News'
      } else if (tag === 'resources') {
        tagTitle = 'Resources'
      } else if (tag === 'tips') {
        tagTitle = 'Tips'
      }

      return prevArr.concat([{title: tagTitle, key: tag, component: <PostCategory tag={tag} />}])
    },
    [{key: 'all', title: 'All', component: <PostCategory tag="" />}],
  )

  const latestPost = latest.edges.length > 0 && latest.edges[0]
  return (
    <ThemeProvider theme={{theme}}>
      <ResetCss />
      <Seo article={false} title={title} description={description} />
      <Navigation />
      {latestPost && (
        <Link to={latestPost.node.fields.slug}>
          <HeaderPost
            variant="post"
            responsiveImages={[
              latestPost.node.frontmatter.coverImage,
              latestPost.node.frontmatter.coverImage,
            ]}
            authorImage={latestPost.node.frontmatter.avatar}
            authorName={latestPost.node.frontmatter.author}
            buttonText="READ POST"
            category={latestPost.node.frontmatter.tag}
            dateTimestamp={Number(latestPost.node.frontmatter.published)}
            title={latestPost.node.frontmatter.title}
          />
        </Link>
      )}
      <Box background="#f7f7f7">
        <Container>
          <Box p={['80px 0', null, null, '120px 0 64px']}>
            <SectionTitleSubtitle title="Featured" subtitle="Don’t miss these hot stories" />
          </Box>
          <Grid
            gridTemplateColumns={['1fr', null, null, '1fr 1fr 1fr']}
            gridGap={['16px 0', null, null, '0 16px']}
            pb={['80px', null, null, '120px']}
          >
            {featured.edges.map(edge => (
              <A to={edge.node.fields.slug} key={edge.node.fields.slug}>
                <FeaturedPost
                  category={edge.node.frontmatter.tag}
                  title={edge.node.frontmatter.title}
                  dateTimestamp={Number(edge.node.frontmatter.published)}
                  authorImage={edge.node.frontmatter.avatar}
                  authorName={edge.node.frontmatter.author}
                  postImage={[edge.node.frontmatter.coverImage]}
                />
              </A>
            ))}
          </Grid>
        </Container>
      </Box>
      <Container>
        <Box p={['80px 0 64px', null, null, '120px 0 64px']}>
          <SectionTitleSubtitle title="Recent" subtitle="Some stories are still warm" />
        </Box>
        <Box>
          <Tabs tabs={tags} />
        </Box>
      </Container>
      <Box mt={['80px', null, null, '120px']}>
        <Subscribe />
      </Box>
      <Footer />
    </ThemeProvider>
  )
}

// @ts-ignore
export const query = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    tagsCount: allMarkdownRemark {
      group(field: frontmatter___tag) {
        fieldValue
        totalCount
      }
    }
    latest: allMarkdownRemark(limit: 1, sort: {fields: [frontmatter___published], order: DESC}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            author
            avatar
            coverImage
            published(formatString: "X")
            tag
            title
          }
        }
      }
    }
    featured: allMarkdownRemark(
      limit: 3
      sort: {fields: [frontmatter___published], order: DESC}
      filter: {frontmatter: {featured: {eq: true}}}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            author
            avatar
            coverImage
            published(formatString: "X")
            tag
            title
          }
        }
      }
    }
  }
`

export default Blog
