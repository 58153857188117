import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
import BlogListItem from 'los-design-system/components/BlogListItem'

const A = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

interface PostProps {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      author: string
      avatar: string
      coverImage: string
      published: string
      tag: string
      title: string
    }
  }
}

export const PostCategoryComponent = ({posts}: {posts: PostProps[]}) => (
  <>
    {posts.map(edge => (
      <A to={edge.node.fields.slug} key={edge.node.fields.slug}>
        <BlogListItem
          key={edge.node.fields.slug}
          responsiveImages={[edge.node.frontmatter.coverImage]}
          authorName={edge.node.frontmatter.author}
          authorImage={edge.node.frontmatter.avatar}
          category={edge.node.frontmatter.tag}
          title={edge.node.frontmatter.title}
          dateTimestamp={Number(edge.node.frontmatter.published)}
        />
      </A>
    ))}
  </>
)

interface PostCategoryQueryProps {
  allMarkdownRemark: {
    edges: PostProps[]
  }
}

const PostCategory = ({tag}: {tag: string}) => {
  const data: PostCategoryQueryProps = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {frontmatter: {featured: {eq: false}}}
        sort: {fields: [frontmatter___published], order: DESC}
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              author
              avatar
              coverImage
              published(formatString: "X")
              tag
              title
            }
          }
        }
      }
    }
  `)
  const posts = data.allMarkdownRemark.edges
    .filter(edge => !tag || edge.node.frontmatter.tag === tag)
    .slice(0, 5)
  return <PostCategoryComponent posts={posts} />
}

export default PostCategory
